
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import AddProductDetailsModal from "@/components/modals/forms/AddProductDetailsModal.vue";
import moment from "moment";

// import DataTable from "@/components/custom/Table.vue";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {
    // AddProductDetailsModal,
    // DataTable,
  },

  setup(props, { emit }) {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const customer_data = ref<Array<Supplier>>([]);
    const customer_branch_data = ref<Array<any>>([]);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const myTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Array<tableDataRefI>>([]);
    const invoiceChargesList:any = ref([]);
    const account_executive_data = ref([]);
    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const group_type_data = ref([]);
    const dialogTableVisible = ref(false);

    const nob_data = ref([]);
    const transport_data = ref([
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 0,
        name: "No",
      },
    ]);

    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const transport_note_data = ref([]);

    const inward_warehouse_data = ref([]);
    const outward_warehouse_data = ref([]);
    // const tableData = ref([]);
    const tableData = ref<Array<any>>([]);
    const myData = ref<Array<tableDataRefI>>([]);

    const branch_data = ref([]);

    interface tableDataRefI {
      sales_dispatch_order_details_id: string;
      sales_dispatch_order_id: string;
      dispatch_order_sr_no: string;
      product_name: string;
      quantity: string;
      uom_name: string;
      product_amount: string;
      grand_total_amount: string;
    }

    const product_data = ref([
      {
        product_id: 2,
        product_name: "AMNS PPGI Coil IS-2062 E250A",
        product_short_name: "AM/NS PPGI Coil IS-2062 E250A",
      },
      {
        product_id: 1,
        product_name: "JSW PPGI Coil IS-2062 E250 BR",
        product_short_name: "JSW PPGI Coil IS-2062 E250 BR",
      },
    ]);

    const reason_data = ref([
      {
        id: 1,
        name: "Stock Inward Adjustment",
      },
      {
        id: 2,
        name: "Stock Outward Adjustment",
      },
      {
        id: 3,
        name: "Stock Transfer",
      },
    ]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Sales Invoice", []);
      getProductChargeList();
      await getAccountExecutiveData();
    });

    const transport_applicable_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addStockJournalVoucharRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getAddData();
    getUOM();
    getProductList();
    getWarehouseList();

    
    function deleteRow(row) {
      myData.value.splice(row, 1);
    }

    const formData = ref({
      sjvd_date: moment().format("YYYY-MM-DD"),
      buyer_select: "",
      buyer_branch_select:"",
      consignee_select: "",
      consignee_branch_select:"",
      bill_no: "",
      bill_date: "",
      distance: "",
      arraned_by_us_select: 0,
      transport_note_select: "",
      transport_name: "",
      vehicle_no: "",
      lr_no: "",
      lr_date: "",
      po_no: "",
      po_date: "",
      account_executive_select:"",
      delivery_from: "",
      tc_reference: "",
      remark:""
    });

    const rules = ref({
      buyer_select: [
        {
          required: true,
          message: "Buyer is required",
          trigger: "change",
        },
      ],
      buyer_branch_select:[
        {
          required: true,
          message: "Buyer Branch is required",
          trigger: "change",
        },
      ],
      consignee_select: [
        {
          required: true,
          message: "Consignee is required",
          trigger: "change",
        },
      ],
      consignee_branch_select: [
        {
          required: true,
          message: "Consignee Branch is required",
          trigger: "change",
        },
      ],
      sjvd_date: [
        {
          required: true,
          message: "Invoice Date is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      let product_list:any = [];
      for(let item of myData.value){
        if(!item['isStatic']){
          product_list.push({
            do_detail_id: item['do_detail_id'],
            billing_quantity: item['dispatch_quantity'], 
          });
        };
      }
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

    let request_body = {
      si_date : moment(data.sjvd_date).format("YYYY-MM-DD"),
      customer_company_id: data.buyer_select,
      customer_company_branch_id : data.buyer_branch_select,
      consignee_company_id: data.consignee_select,
      consignee_company_branch_id : data.consignee_branch_select,
      eway_distance_km : data.distance,
      transport_yn : data.arraned_by_us_select,
      tn_id : data.arraned_by_us_select == 1 ? data.transport_note_select : 0,
      po_no : data.po_no,
      po_date : moment(data.po_date).format("YYYY-MM-DD"),
      delivery_from : data.delivery_from,
      tc_reference : data.tc_reference,
      remarks : data.remark,
      user_id : user.user_id,
      own_user_id : data.account_executive_select,
      igst_yn : myData.value[0]['igst_yn'],
      product_data : product_list,
      charge_data: invoiceChargesList.value
    }

      console.log(request_body);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALES_INVOICE, request_body)
          .then(({ data, is_error, status }) => {
            loading.value = false;
            console.log(data);
            if (is_error == false){
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                html: ""+

                      data?.si_no+

                      "<br>Sales invoice created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-sales-sales-invoice-listing" });
                emit("refreshList");
              });
            }, 2000);
            }else{
              loading.value = false;
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    
    async function getTableData() {
      debugger;
      let params = {
        tn_id: formData.value.arraned_by_us_select == 1 ? formData.value.transport_note_select : 0,
        customer_company_id: formData.value["buyer_select"],
        customer_company_branch_id :formData.value['buyer_branch_select']
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_DO, params)
        .then(({ data }) => {
          debugger;
          if (!data || data?.length <= 0) {
            tableData.value = [];
          } else {
            // data.map((order) => {
            //   order.quantity = order.quantity + " " + order.uom_name;
            // });
            tableData.value = data;
              let newData = tableData.value.map((item) => {
              return { ...item, isCheck: false,constDispatchQty: item.dispatch_quantity, remark: ""};
           });
            tableData.value = newData;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      getTableData();
      dialogTableVisible.value = true;
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTransportNote() {
      let params = {
          customer_company_id: formData.value["buyer_select"],
          customer_company_branch_id : formData.value['buyer_branch_select']
      };
      await store
        .dispatch(ActionsFi.CUST_GET_TN_LIST_BY_BYER, params)
        .then(({ data }) => {
          transport_note_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function Required() {
      return { color: "red" };
    }
    async function getReason() {
      await store
        .dispatch(ActionsFi.CUST_GET_REASON)
        .then(({ data }) => {
          reason_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCustomerBranchList(branchFor = 'buyer') {
      let values = {
        company_id: branchFor == 'buyer' ? formData.value["buyer_select"] :
                                formData.value["consignee_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getProductChargeList = async() => {
      var user = JSON.parse(JwtService.getToken());
      let param = {user_id: user.user_id}
      try {
        await store
          .dispatch(ActionsFi.CUST_SALES_INVOICE_CHARGE_LIST,param)
          .then(({ data }) => {
            debugger;
            invoiceChargesList.value = data;
            console.log('invoice charges list', invoiceChargesList.value)
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    async function getAccountExecutiveData() {
      await store
        .dispatch(ActionsFi.CUST_GET_MAINTAIN_BY_LIST)
        .then(({ data }) => {
          account_executive_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onCheckAndInput = (editedRow) => {
      if (!/^\d*\.?\d*$/.test(editedRow.dispatch_quantity)) {
        editedRow.dispatch_quantity = editedRow.dispatch_quantity.slice(0, -1);
        return;
      }else
         if (
          parseFloat(editedRow.dispatch_quantity) >
          parseFloat(editedRow.constDispatchQty)
        ) {
          editedRow.dispatch_quantity = "";
          Swal.fire({
            text: "Please enter Billing qty less than or equl to Dispatch Order qty",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        
      }
    };

    async function getProductList() {
      var values = { search_term: "", company_id: 0 };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          product_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function logReason() {
      console.log("reason is..." + formData.value["reason_select"]);
    }


    async function getWarehouseList() {
      let values = {
        company_id: "1",
        // page: 1,
        // records_per_page: 10,
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          inward_warehouse_data.value = data;
          outward_warehouse_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onContinue = () => {
      for (let data of tableData.value) {
        if (data.isCheck && !data.dispatch_quantity) {
          Swal.fire({
            text: "Please enter remaining qty.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loading.value = false;
          return;
        }

      let productDetails: any = [];
      for (let data of tableData.value) {
        if (data.isCheck && data.dispatch_quantity){
          data.amount = (parseFloat(data.dispatch_quantity) * parseFloat(data.product_rate)).toFixed(4);
          if(data.igst_yn === 1){
            data.igst = (parseFloat(data.amount) * data.tax_percent_json.igst /100).toFixed(4);
            data.total_amount = (parseFloat(data.amount) + parseFloat(data.igst)).toFixed(4);
          }else{
            data.cgst = (parseFloat(data.amount) * 9 /100).toFixed(4);
            data.sgst = (parseFloat(data.amount) * data.tax_percent_json.sgst /100).toFixed(4);
            data.total_amount = (parseFloat(data.amount) + parseFloat(data.cgst) +
                                       parseFloat(data.cgst)).toFixed(4);
          }
          productDetails.push(data);
        }
      }

      if (productDetails.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
        else {
          myData.value = productDetails;
          setStaticFiledData();
          myData.value = productDetails.concat(invoiceChargesList.value);
          // myData.value[0]['igst_yn'] = 0
          
          console.log('myData', myData)
          dialogTableVisible.value = false;
      }
      
      } 
    };


    const setStaticFiledData = () => {
        let productAmountSubTotal = 0;
        let igstSubTotal = 0;
        let cgstSubTotal = 0;
        let sgstSubTotal = 0;
        let grandSubTotal = 0;
        let productAmountTotal = 0;
        let igstTotal = 0;
        let cgstTotal = 0;
        let sgstTotal = 0;
        let grandTotal = 0;
        let round = 0;
        let roundUp = 0;
        let qtyTotal = 0;

      invoiceChargesList.value.map((row)=>{
        row.charge_amount= '',
        row.igst_amount= '',
        row.cgst_amount='',
        row.sgst_amount='',
        row.charge_total_amount= ''
      });

      myData.value.map((data: any) => {
          productAmountSubTotal = parseFloat(data?.amount) + productAmountSubTotal;
          grandSubTotal = parseFloat(data?.total_amount) + grandSubTotal;
          qtyTotal = parseFloat(data?.dispatch_quantity) + qtyTotal;
          if(myData.value[0]['igst_yn'] === 1){
            igstSubTotal = parseFloat(data?.igst) + igstSubTotal;
          }else{
            cgstSubTotal = parseFloat(data?.cgst) + cgstSubTotal;
            sgstSubTotal = parseFloat(data?.sgst) + sgstSubTotal;
          }
       });

      invoiceChargesList.value.map((row) => {
          //  let productAmount = row?.charge_amount ? row.charge_amount : '0'
           let grandAount =  row?.charge_total_amount ? row.charge_total_amount : '0'
           
          //  productAmountTotal = parseFloat(productAmount) + productAmountTotal;
           grandTotal = parseFloat(grandAount) + grandTotal;
           if(myData.value[0]['igst_yn'] === 1){
            let igstAmount =  row?.igst_amount ? row.igst_amount : '0'
            igstTotal = parseFloat(igstAmount) + igstTotal;
           }else{
            let cgstAmount =  row?.cgst_amount ? row.cgst_amount : '0';
            let sgstAmount = row?.sgst_amount ? row.sgst_amount : '0';
            cgstTotal = parseFloat(cgstAmount) + cgstTotal;
            sgstTotal = parseFloat(sgstAmount) + sgstTotal;
           }
          
      });
       
      round = Math.round(grandTotal + grandSubTotal);
      invoiceChargesList.value.map((row)=> {
          if(row?.charge_id === 100){
           row.charge_amount = productAmountSubTotal.toString();
           row.charge_total_amount = grandSubTotal.toString();
           row.rate = qtyTotal.toFixed(2);
           if(myData.value[0]['igst_yn'] === 1){
            row.igst_amount  = igstSubTotal.toString();
           }else{
            row.cgst_amount = cgstTotal.toString();
            row.sgst_amount = sgstTotal.toString();
           }
          }
          // else if(row.charge_id === 6){
          //   row.charge_amount = grandSubTotal.toString()
          // }
          else if(row.charge_id === 101){
            // row.charge_amount = (productAmountTotal+ productAmountSubTotal).toString();
            row.charge_total_amount = (grandTotal + grandSubTotal).toString();
            if(myData.value[0]['igst_yn'] === 1){
              row.igst_amount = (igstTotal + igstSubTotal).toString();
            }else{
              row.cgst_amount = (cgstTotal + cgstSubTotal).toString();
              row.sgst_amount = (sgstTotal + sgstSubTotal).toString();
            }
          }
          else if(row.charge_id === 102){
            roundUp = grandTotal + grandSubTotal - round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }

          else if(row.charge_id === 103){
            row.charge_total_amount = parseFloat((round).toString()).toFixed(2);
          }
      })
    }


  const calculateStaticTableFields = (editedRow, column_name) => {
      if(editedRow[column_name] === ''){
          editedRow.charge_amount = '';
          editedRow.igst_amount = '';
          editedRow.cgst_amount = '';
          editedRow.sgst_amount = '';
          editedRow.charge_total_amount = '';
      }
        else if(!(/^\d*\.?\d*$/.test(editedRow[column_name]))){
         editedRow[column_name] = editedRow[column_name].slice(0, -1);;
         return;
      }
      else if([6].includes(editedRow.charge_id)){
        return;
      }
            
      console.log('static selected row',editedRow)
      let calculatedValue = 0;
      let productAmountColumn = parseFloat(editedRow.charge_amount ? editedRow.charge_amount : '0');
      let igstAmountColumn = parseFloat(editedRow.igst_amount);
      let productGrandTotalColumn =  parseFloat(editedRow.charge_total_amount);

      if(myData.value[0]['igst_yn'] === 1){
        let igst  = (productAmountColumn * 18)/100;
        calculatedValue =  productAmountColumn + igst;
        editedRow.igst_amount = igst.toString();
      }else{
        let cgst = (productAmountColumn * 0.9)/100;
        let sgst = (productAmountColumn * 0.9)/100;
        calculatedValue =  productAmountColumn + cgst + sgst;
        editedRow.cgst_amount = cgst.toString();
        editedRow.sgst_amount = sgst.toString();
      }

      editedRow.charge_total_amount = calculatedValue.toString();

      let chargeAmount = 0;
      let igstChargeAmount = 0;
      let cgstChargeAmount = 0;
      let sgstChargeAmount  = 0;
      let chargeTotalAmount = 0;
      let roundUp = 0;
      let round = 0;
        for(let row of invoiceChargesList.value){
          if([6, 101, 102, 103].includes(row.charge_id)){
            continue;
          }
            let chargeAmountVal = row.charge_amount ? row.charge_amount : '0';
            let chargeTotalAmountVal = row.charge_total_amount ? row.charge_total_amount : '0';
            chargeAmount = parseFloat(chargeAmountVal) + chargeAmount;
            chargeTotalAmount = parseFloat(chargeTotalAmountVal) + chargeTotalAmount;
            if(myData.value[0]['igst_yn'] === 1){
              let igstAmountVal = row.igst_amount ? row.igst_amount : '0';
              igstChargeAmount = parseFloat(igstAmountVal) + igstChargeAmount;
            }else{
              let cgstAmount = row.cgst_amount ? row.cgst_amount : '0';
              let sgstAmount = row.sgst_amount ? row.sgst_amount : '0';
              cgstChargeAmount = parseFloat(cgstAmount) + cgstChargeAmount;
              sgstChargeAmount = parseFloat(sgstAmount) + sgstChargeAmount;
            }
        }


        invoiceChargesList.value.map((row) => {
         if(row.charge_id === 101){
           row.charge_amount = chargeAmount;
           row.charge_total_amount = parseFloat(chargeTotalAmount.toString()).toFixed(2);
           if(myData.value[0]['igst_yn'] === 1){
            row.igst_amount = igstChargeAmount;
           }else{
            row.cgst_amount = cgstChargeAmount;
            row.sgst_amount = sgstChargeAmount;
           }
           let toatlCharge  = chargeTotalAmount;
          round = Math.round(toatlCharge);
         }
          else if(row.charge_id === 102){
            roundUp = chargeTotalAmount-round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }
         else if(row.charge_id === 103){
           row.charge_total_amount = parseFloat(round.toString()).toFixed(2)
         }
     })

    }
    
    const resetFormData = () => {
      formData.value = {
          sjvd_date: moment().format("YYYY-MM-DD"),
          buyer_select: "",
          buyer_branch_select:"",
          consignee_select: "",
          consignee_branch_select:"",
          bill_no: "",
          bill_date: "",
          distance: "",
          arraned_by_us_select: 0,
          transport_note_select: "",
          transport_name: "",
          vehicle_no: "",
          lr_no: "",
          lr_date: "",
          po_no: "",
          po_date: "",
          account_executive_select:"",
          delivery_from: "",
          tc_reference: "",
          remark:""
        };
        invoiceChargesList.value = [];
        myData.value=[];

    }

    const handleSelectionChange = (val: tableDataRefI[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogTableVisible.value = false;
    };

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addStockJournalVoucharRef,
      uom_data,
      reason_data,
      logReason,
      transport_applicable_data,
      tableData,
      myData,
      product_data,
      deleteRow,
      getReason,
      getProductList,
      branch_data,
      inward_warehouse_data,
      outward_warehouse_data,
      Required,
      getWarehouseList,
      transport_data,
      getCompanyList,
      customer_data,
      dialogTableVisible,
      getTableData,
      openDilogue,
      onContinue,
      onCancle,
      multipleTableRef,
      myTableRef,
      multipleSelection,
      transport_note_data,
      getTransportNote,
      handleSelectionChange,
      
      getCustomerBranchList,
      customer_branch_data,
      onCheckAndInput,
      invoiceChargesList,
      calculateStaticTableFields,
      account_executive_data,
      resetFormData
    };
  },
});
